import "@seed-design/stylesheet/global.css";
import "@stackflow/plugin-basic-ui/index.css";
import "./src/styles/global.css";

import React from "react";
import { type WrapPageElementBrowserArgs } from "gatsby";

import { BridgeProvider } from "./src/bridge";
import { Stack } from "./src/Stack";

export const wrapPageElement = ({
  props: { location, data },
}: WrapPageElementBrowserArgs) => {
  return (
    <>
      <BridgeProvider>
        <React.Suspense>
          <Stack initialContext={{ req: { path: location.pathname }, data }} />
        </React.Suspense>
      </BridgeProvider>
    </>
  );
};
